$(function () {

	//メインビジュアルスライドショー
	$('.mv_swiper').imagesLoaded( function() {
		//キャッチコピーフェードイン
		$(".mv_copy h1, .scroll_animation , .mv_copy_sp").addClass("fadein");
		//メインビジュアルスライドショ
		var mySwiper = new Swiper('.mv_swiper', {
			loop: true,
			speed: 1500,
			effect: 'fade',
			allowTouchMove: false,
			// slidesPerView: 1,
			autoplay: {
				delay: 3000,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		});
	});

	//サービススライド(スマホのみ)
	var banner_swiper;
	$(window).on('load resize', function(){
		var w = $(window).width();
		if (w <= 768) {
			if (banner_swiper) {
				return;
			} else {
				banner_swiper = new Swiper('.banner_swiper', {
					loop: true,
					// speed: 1500,
					autoplay: {
						delay: 4000,
					},
					spaceBetween: 19,
					slidesPerView: 1.23,
					centeredSlides: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				});
			}
		} else {
			if (banner_swiper) {
				banner_swiper.destroy();
				banner_swiper = undefined;
			}
		}
	});

	//施工実績スライドショー
	var swiper = new Swiper(".works_swiper", {
		loop: true,
		centeredSlides: true,
		spaceBetween: 1,
		autoplay: {
			delay: 3000,
		},
		breakpoints: {
			320: {
				slidesPerView: 1.25,
			},
			// 768px以上の場合
			767: {
				slidesPerView: 'auto',
			},
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
	});

});