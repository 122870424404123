$(window).on("load",function(){
	$("body").removeClass("preload");
});
$(function(){

	var $body = $('body');

	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/wp/wp-content/themes/sennin/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$('body').prepend(svg);
	});

	// スクロールアニメーション
	new WOW().init();

	//ページ内スクロール
	$.smooziee();

	//スクロールしたらクラスを付与
	$(window).on('scroll',function(){
		if ($(this).scrollTop() > 100) {
			$body.addClass("scroll");
		} else {
			$body.removeClass("scroll");
			// $('.header').removeClass('hide');
		}
	});

	//スクロール上下でクラスを付与
	var beforePos = 0;//スクロールの値の比較用の設定
	function ScrollAnime() {
		var elemTop = 100
		// var elemTop = $('#area-2').offset().top;//#area-2の位置まできたら
		var scroll = $(window).scrollTop();
		//ヘッダーの出し入れをする
		if(elemTop > scroll || 0 > scroll - beforePos){
			$body.addClass("scroll_up");
			$body.removeClass('scroll_down');
		}else {
			$body.addClass("scroll_down");
			$body.removeClass('scroll_up');
		}
		beforePos = scroll;//現在のスクロール値を比較用のbeforePosに格納
	}
	// 画面をスクロールをしたら動かしたい場合の記述
	$(window).on('scroll', function(){
		ScrollAnime();
	});
	// ページが読み込まれたらすぐに動かしたい場合の記述
	$(window).on('load', function () {
		ScrollAnime();
	});

	//開閉用ボタンをクリックでクラスの切替え
	$('.header_btn').on('click', function () {
		$body.toggleClass('open');
		// $subsidy.toggleClass('open');
		return false;
	});

	//ヘッダードロップダウンメニュー
	$('.submenu_btn').on('click', function () {
		$(this).toggleClass('on');
		$(this).next(".submenu_wrap").toggleClass('on');
		$(".submenu_btn").not(this).removeClass("on");
		$(".submenu_btn").not(this).next().removeClass("on");
		$(".submenu_btn").not(this).next().slideUp(300);
		$(this).next().slideToggle(300);
		
		if($(".submenu_btn").hasClass('on')){
			$('.layer').addClass("on");
		} else {
			$('.layer').removeClass("on");
		}
		return false;
	});

	//固定CV フッター前で非表示
	$(window).on("scroll", function () {
		scrollHeight = $(document).height();
		scrollPosition = $(window).height() + $(window).scrollTop();
		footHeight = $(".footer").innerHeight();
		if (scrollHeight - scrollPosition <= footHeight) {
			$(".fix_cv").addClass("fix_off");
		} else {
			$(".fix_cv").removeClass("fix_off");
		}
	});


    // イベントとリフォームの入力ページ以外では実行させない
    if( ($('body.single-event').length) || ($('body.single-reform').length) ){
		//固定CV フォーム前で非表示
		$(window).on("scroll", function () {
			var form_block = $(".form_block").offset().top; // ターゲットの位置取得
			var haakaPosition = form_block - $(window).height(); // 発火させたい位置
			if($(window).scrollTop() > haakaPosition){
				// 要素が見えたときの動き
				$(".fix_cv").addClass("fix_off");
			} else {
				// それ以外の動き
				// $(".fix_cv").removeClass("fix_off");
			}
		});
	}

	//アコーディオン
	// $(".acd_content").hide();
	$(".faq_list li").on("click", function() {
		if($(this).hasClass('open')){
			$(".faq_list li").removeClass("open");
			$(this).removeClass("open");
			// $(this).find(".acd_content").slideUp();
		} else {
			// $(".acd_content").slideUp();
			// $(".acd_head").removeClass("open");
			$(".faq_list li").removeClass("open");
			$(this).addClass("open");
			// $(this).find(".acd_content").slideToggle();
		}
	});

	//ライトボックス(PCのみ)
	// if (window.matchMedia( "(min-width: 768px)" ).matches) {
	// 	$('.popup-modal-single').magnificPopup({
	// 		type: 'image',
	// 		gallery: {
	// 			enabled: true,
	// 		}
	// 	});
	// }

	//ループスライドショー
	var mySwiper = new Swiper('.slide_swiper', {
		loop: true,
		speed: 8000,
		slidesPerView: 'auto',
		allowTouchMove: false,
		autoplay: {
			delay: 0,
			disableOnInteraction: false,
		},
		on: {
			touchEnd: () => {
				getTranslate = mySwiper.getTranslate(); // スライドの現在位置を取得
				slideWidth = document.querySelector('.swiper-slide-active').offsetWidth;//スライドの横幅を取得
				mySwiper.setTranslate(getTranslate - slideWidth); // translateを更新
				mySwiper.setTransition(14000); //transitonを更新
			},
		}
	});

	// フォームでタイトル名を入力できないように
	$('input[name="dynamictitle"]').attr('readonly',true);
	$('input[name="reformtitle"]').attr('readonly',true);

	// エントリーフォームカタ自動補完
	$.fn.autoKana('#applicant', '#kana', {
		katakana : true  //true：カタカナ、false：ひらがな（デフォルト）
	});

	// エントリーフォームカタ自動補完
	// $.fn.autoKana('#name', '#kana', {
	// 	katakana : true  //true：カタカナ、false：ひらがな（デフォルト）
	// });

	// 住所自動入力
	$('#zip').attr("onKeyUp", "AjaxZip3.zip2addr(this,'','pref','address');");

	// 住所自動補完が完了したら
	AjaxZip3.onSuccess = function() {
		$('input[name=address]').focus(); // 番地、建物名にフォーカス
		// $('select[name=prefectures]').css({'color':'#000'}); //色変更
	};

	/*クリックボックス*/
	$(".clickbox").click(function(){
		if($(this).find("a").attr("target")=="_blank"){
			window.open($(this).find("a").attr("href"), '_blank');
		}else{
			window.location=$(this).find("a").attr("href");
		}
	return false;
	});

});