$(function () {

	//アンカーでもタブ切り替え
	$(".recruit_nav_list li").on("click",function(){
		var $count = $(this).index();
		// console.log($count);
		$(".tab_label").eq($count).trigger("click");
	});

	//タブ切り替え
	$(".tab_label").on("click",function(){
		var $th = $(this).index();
		$(this).parent().find(".tab_label").removeClass("active");
		$(this).parent().next().find(".tab_panel").removeClass("active");
		$(this).addClass("active");
		$(this).parent().next().find(".tab_panel").eq($th).addClass("active");
		// console.log($th);
	});

});